import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { getWeekDays } from '../services/utils';
import { Meal, MealType } from '../types/mealType';
import { UserProfile } from '../pages/Profile';
import { createMealPlanning, UserDietInfo } from '../services/diet';
import { getLastCachedMealPlanning } from '../services/mealService';
import { CACHE_KEY_MEAL_PLANNING } from '../services/cacheService';
import { useUserProvider } from './UserProvider';

interface MealPlanningContextType {
    activeWeek: Date[];
    setActiveWeek: React.Dispatch<React.SetStateAction<Date[]>>;
    currentWeekOffset: number,
    mealPlanning: {[day: string]: { [key in MealType]?: Meal }};
    setMealPlanning: React.Dispatch<React.SetStateAction<{[day: string]: { [key in MealType]?: Meal }}>>,
    mealPlanningProviderReady: boolean,
    changeWeek: (offset: number) => void,
    updateMealPlanning: (mealPlanning: {[day: string]: { [key in MealType]?: Meal }}) => void
}

// Create the context
const MealPlanningContext = createContext<MealPlanningContextType>({
    activeWeek: [],
    setActiveWeek: () => {},
    currentWeekOffset: 0,
    mealPlanning: {},
    setMealPlanning: () => {},
    mealPlanningProviderReady: false,
    changeWeek: () => {},
    updateMealPlanning: () => {}
});

// Optional: Custom hook for easier usage
export const useMealPlanning: () => MealPlanningContextType = () => useContext(MealPlanningContext);

// Context provider component
export const MealPlanningProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const { profile, dietInfo } = useUserProvider();
    const [activeWeek, setActiveWeek] = useState<Date[]>(getWeekDays(0));
    const [currentWeekOffset, setCurrentWeekOffset] = useState<number>(0);
    const [mealPlanning, setMealPlanning] = useState<{[day: string]: { [key in MealType]?: Meal }}>(createMealPlanning(dietInfo, Number(profile.meal_schedule), activeWeek));
    const [mealPlanningProviderReady, setMealPlanningProviderReady] = useState<boolean>(false);
    const [cachedMeals, setCachedMeals] = useState<{ [day: string]: { [type: string]: Meal } } | {}>({});

    useEffect(() => {
        setMealPlanningProviderReady(false);
        getLastCachedMealPlanning(activeWeek).then((cachedMeals) => {
            if (!cachedMeals) {
                console.error('Cached meals not initialized properly');
                return;
            }
            updateMealCache(cachedMeals);  // Update local cache with received data
        }).catch((error) => {
            console.error('Error fetching updated meals:', error);
        }).finally(() => {
            setMealPlanningProviderReady(true);
        })
    }, [profile.meal_schedule, activeWeek]);

    const updateMealCache = (_cachedMeals: { [day: string]: { [type: string]: Meal } }) => {
        let newMealPlanning: { [day: string]: { [key in MealType]?: Meal } } = createMealPlanning(dietInfo, Number(profile.meal_schedule), activeWeek);
        Object.keys(newMealPlanning).forEach((day) => {
            (Object.keys(newMealPlanning[day]) as MealType[]).forEach((mealType: MealType) => {
                if (_cachedMeals[day] && _cachedMeals[day][mealType]) {
                    // There is a cached meal
                    newMealPlanning[day][mealType] = {
                        ...newMealPlanning[day][mealType],
                        ..._cachedMeals[day][mealType],
                        date: new Date(_cachedMeals[day][mealType].date),
                        type: mealType // Ensure type is set to MealType
                    };
                } else {
                    // There is no cached meal, so add the one from newMealPlanning
                    if (!_cachedMeals[day]) {
                        _cachedMeals[day] = {};
                    }
                    _cachedMeals[day][mealType] = {
                        ...newMealPlanning[day][mealType]!,
                        date: new Date(newMealPlanning[day][mealType]!.date),
                    };
                }
            });
        });
        localStorage.setItem(CACHE_KEY_MEAL_PLANNING, JSON.stringify(_cachedMeals));
        setCachedMeals(_cachedMeals);
        setMealPlanning(newMealPlanning);
    };

    const updateMealPlanning = (_mealPlanning: { [day: string]: { [key in MealType]?: Meal } }) => {
        let newCachedMeals: { [day: string]: { [key in MealType]?: Meal } } = cachedMeals;
        Object.keys(_mealPlanning).forEach((day) => {
            (Object.keys(_mealPlanning[day]) as MealType[]).forEach((mealType: MealType) => {
                _mealPlanning[day][mealType]!.mealGenerationRunning = false;
                _mealPlanning[day][mealType]!.date = new Date(_mealPlanning[day][mealType]!.date);
                if (newCachedMeals[day] && newCachedMeals[day][mealType]) {
                    // There is a cached meal
                    newCachedMeals[day][mealType] = {
                        ...newCachedMeals[day][mealType],
                        ..._mealPlanning[day][mealType]!,
                    };
                } else {
                    // There is no cached meal, so add the one from newMealPlanning
                    if (!newCachedMeals[day]) {
                        newCachedMeals[day] = {};
                    }
                    newCachedMeals[day][mealType] = {
                        ..._mealPlanning[day][mealType]!,
                    };
                }
            });
        });
        localStorage.setItem(CACHE_KEY_MEAL_PLANNING, JSON.stringify(newCachedMeals));
        setCachedMeals(newCachedMeals);
        setMealPlanning(_mealPlanning);
    }

    const changeWeek = (offset: number) => {
        const newDays = getWeekDays(offset);
        setCurrentWeekOffset(offset);
        setActiveWeek(newDays);
        setMealPlanning(createMealPlanning(dietInfo, Number(profile.meal_schedule), newDays));
    }


    return (
        <MealPlanningContext.Provider value={{ activeWeek, setActiveWeek, currentWeekOffset, mealPlanning, setMealPlanning, mealPlanningProviderReady, changeWeek, updateMealPlanning }}>
            {children}
        </MealPlanningContext.Provider>
    );
};