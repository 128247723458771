import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, Link } from 'react-router-dom';
import Planning from '../components/Planning';
import Profile, { UserProfile } from './Profile';
import { getUserDietInfo, UserDietInfo } from '../services/diet';
import Recipes from './Recipes';
import apiClient from '../services/apiClient';
import { useNotification } from '../provider/NotificationProvider';
import ShoppingList from './ShoppingList';
import { MealPlanningProvider } from '../provider/MealPlanningProvider';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Bars3Icon } from '@heroicons/react/24/solid';
import { SimpleLoading } from '../components/Loading';
import { useUserProvider } from '../provider/UserProvider';

const Dashboard: React.FC = () => {

  const [currentPage, setCurrentPage] = useState('dash');
  const [isOpen, setIsOpen] = useState(false);

  const { dietInfo, userProviderReady } = useUserProvider();

  // Show loading while profile data is not yet available
  if (!userProviderReady) {
    return <div className="flex h-screen items-center justify-center">
      <SimpleLoading text="On prépare votre profil..." />
    </div>
  }

  return (
    <div className="dashboard bg-nutrimmy-bg">

      <nav className="bg-white border-b border-gray-100">
        <div className="container mx-auto px-4 flex justify-between items-center py-4">

          {/* Logo */}
          <h1 className="font-bold text-rose-500 lowercase text-2xl">
            <Link to="/dashboard" onClick={() => setCurrentPage('dash')}>
              Nutrimmy
            </Link>
          </h1>

          {/* Desktop Links */}
          <div className="hidden md:flex space-x-8">
            <Link to="/dashboard" className={currentPage === 'dash' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('dash')}>
              Planning
            </Link>
            <Link to="/dashboard/shopping-list" className={currentPage === 'shopping-list' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('shopping-list')}>
              Liste de courses
            </Link>
            <Link to="/dashboard/recipes" className={currentPage === 'recipes' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('recipes')}>
              Recettes
            </Link>
            <Link to="/dashboard/profile" className={currentPage === 'profile' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => setCurrentPage('profile')}>
              Profil
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <div className="md:hidden">
            <button onClick={() => setIsOpen(!isOpen)} className="text-gray-600 hover:text-gray-900 focus:outline-none">
              {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="md:hidden bg-white border-t border-gray-100">
            <div className="flex flex-col space-y-4 p-4">
              <Link to="/dashboard" className={currentPage === 'dash' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => { setCurrentPage('dash'); setIsOpen(false); }}>
                Planning
              </Link>
              <Link to="/dashboard/shopping-list" className={currentPage === 'shopping-list' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => { setCurrentPage('shopping-list'); setIsOpen(false); }}>
                Liste de courses
              </Link>
              <Link to="/dashboard/recipes" className={currentPage === 'recipes' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => { setCurrentPage('recipes'); setIsOpen(false); }}>
                Recettes
              </Link>
              <Link to="/dashboard/profile" className={currentPage === 'profile' ? 'font-bold text-gray-900' : 'text-gray-600'} onClick={() => { setCurrentPage('profile'); setIsOpen(false); }}>
                Profil
              </Link>
            </div>
          </div>
        )}
      </nav>
      <MealPlanningProvider>
        <Routes>
          <Route path="/" element={
            <div className="content">
              {/* <ProfileStatistics profile={profile} dietInfo={dietInfo} /> */}
              {dietInfo && <Planning />}
            </div>
          } />
          <Route path="/shopping-list" element={<ShoppingList />} />
          <Route path="/recipes/*" element={<Recipes />} />
          <Route path="/profile" element={<Profile />} />
        </Routes>
      </MealPlanningProvider>
    </div>
  );
};

export default Dashboard;
