// src/pages/Profile.tsx
import React from 'react';
import EditProfile, { DietInfoCard } from '../components/EditProfile';
import { getBMIReview } from '../services/diet';
import { ArrowLeftStartOnRectangleIcon } from '@heroicons/react/24/solid'
import { useUserProvider } from '../provider/UserProvider';

export interface UserProfile {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  display_name: string;
  birthdate: string;
  gender: string;
  height: number;
  weight: number;
  regime: string;
  objective: string;
  gain_per_week: number;
  activity_level: string;
  weight_goal: number;
  meal_schedule: string;
}

const Profile: React.FC = () => {
  const { profile, dietInfo, handleLogout } = useUserProvider();

  return (
    <div className="p-2 md:p-8">
      <h1 className="text-2xl mb-8 font-bold">Voici votre résumé {profile.first_name}</h1>

      <div className="flex flex-col md:flex-row gap-8">

        <EditProfile />

        <DietInfoCard
          bmi={dietInfo?.bmi}
          bmiReview={getBMIReview(dietInfo?.bmi)}
          estimatedGoalTimeline={dietInfo?.estimatedGoalTimeline}
          calorieDailyIntake={dietInfo?.calorieDailyIntake}
          proteinIntake={dietInfo?.proteinIntake}
          carbIntake={dietInfo?.carbIntake}
          fatIntake={dietInfo?.fatIntake}
        />

      </div>

      <button onClick={handleLogout} className="flex gap-2 mt-32 items-center bg-red-800 text-white px-4 py-1 rounded hover:bg-red-700">
        <ArrowLeftStartOnRectangleIcon className="h-5 w-5" />
        <p>Se déconnecter</p>
      </button>
    </div>
  );
};

export default Profile;
