import React, { act, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import apiClient from '../services/apiClient';
import { getCurrentWeekDays } from '../services/utils';
import { FOOD_CATEGORY_FR } from '../services/foodService';
import { LoadingFullScreen, SimpleLoading } from '../components/Loading';
import { getLastCachedMealPlanning } from '../services/mealService';
import { Meal, MealType } from '../types/mealType';
import { useMealPlanning } from '../provider/MealPlanningProvider';
import { RadioButton } from '../components/WeekPlanGenerator';

const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
const getDayName = (dateString: string): string => {
    const date = new Date(dateString);
    return dayNames[date.getUTCDay()];
};

type ShoppingListProps = {
    [day: string]: {
        [category: string]: {
            [ingredientName: string]: {
                unit: string;
                quantity: number;
            };
        };
    };
};

type WeeklyShoppingListProps = {
    [category: string]: {
        [ingredientName: string]: {
            unit: string;
            quantity: number;
            days: string[];
        };
    };
};

const ShoppingList: React.FC<{}> = () => {
    const FRENCH_DAYS = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    // const days = getCurrentWeekDays();
    const { mealPlanning, currentWeekOffset, changeWeek } = useMealPlanning();


    const [selectedDay, setSelectedDay] = useState<string | undefined>(undefined);
    const [shoppingList, setShoppingList] = useState<ShoppingListProps>({});
    const [weeklyShoppingList, setWeeklyShoppingList] = useState<WeeklyShoppingListProps>({});

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(false);
        // getLastCachedMealPlanning(days).then((cacheMealPlanning: { [day: string]: { [key in MealType]?: Meal } }) => {
        const newList: ShoppingListProps = {};
        const weeklyList: WeeklyShoppingListProps = {};

        Object.values(mealPlanning).flatMap(dayMeals => Object.values(dayMeals).filter(Boolean)).forEach((meal: any) => {
            const frenchDay = getDayName(meal.date);

            for (const ingredient of meal.ingredients) {
                const category = ingredient.food_details.food_category;

                // Daily list grouping by category
                newList[frenchDay] = newList[frenchDay] || {};
                newList[frenchDay][category] = newList[frenchDay][category] || {};
                newList[frenchDay][category][ingredient.name] = newList[frenchDay][category][ingredient.name] || {
                    unit: ingredient.portion_name,
                    quantity: 0,
                };
                newList[frenchDay][category][ingredient.name].quantity += Number(ingredient.portion_quantity);

                // Weekly list grouping by category
                weeklyList[category] = weeklyList[category] || {};
                weeklyList[category][ingredient.name] = weeklyList[category][ingredient.name] || {
                    unit: ingredient.portion_name,
                    quantity: 0,
                    days: [],
                };
                weeklyList[category][ingredient.name].quantity += Number(ingredient.portion_quantity);
                if (!weeklyList[category][ingredient.name].days.includes(frenchDay)) {
                    weeklyList[category][ingredient.name].days.push(frenchDay);
                }
            }
        });

        setShoppingList(newList);
        setWeeklyShoppingList(weeklyList);
        // }).catch((error) => {
        //     console.error(error);
        // })
        //     .finally(() => {
        //     })
        setLoaded(true);
    }, [mealPlanning]);

    return (
        <div className='p-2 md:p-8 py-16 bg-white'>
            <RadioButton radioDisplay={['Cette semaine', 'Semaine prochaine']} radioValues={[0, 1]} selectedValue={currentWeekOffset} setSelectedValue={changeWeek} />
            <h4 className="text-3xl font-bold text-gray-800 mb-8">Ma liste de course pour {currentWeekOffset === 0 ? 'cette semaine' : 'la semaine prochaine'}</h4>

            <div className="flex flex-wrap items-center gap-2 mb-8">
                <div
                    onClick={() => setSelectedDay(undefined)}
                    className={`w-fit cursor-pointer rounded-lg p-2 py-1 ${selectedDay ? 'bg-gray-200 text-gray-800' : 'bg-rose-500 text-white'}`}
                >
                    <div className="w-full">Toute la semaine</div>
                </div>
                {FRENCH_DAYS.map((day, index) => (
                    <div
                        key={index}
                        onClick={() => setSelectedDay(day)}
                        className={`w-fit cursor-pointer rounded-lg p-3 py-1 ${selectedDay === day ? 'bg-rose-500 text-white' : 'bg-gray-200 text-gray-800'}`}
                    >
                        <div className="w-full">{day}</div>
                    </div>
                ))}
            </div>

            {
                !loaded && (
                    <SimpleLoading text='Chargement de la liste de course...' />
                )
            }

            {/* Display Shopping List */}
            {selectedDay === undefined ? (
                // Consolidated weekly shopping list by category
                <div className="mb-6">
                    {
                        Object.keys(weeklyShoppingList).length === 0 && (
                            <p className="text-gray-700 text-sm">Remplissez votre planning de repas pour voir votre liste de course.</p>
                        )
                    }
                    {Object.keys(weeklyShoppingList).map((category) => (
                        <div key={category} className="mb-4">
                            <h5 className="uppercase font-semibold text-sm text-gray-700 mb-2">{FOOD_CATEGORY_FR[String(category)]}</h5>
                            <ul className="pl-2 md:pl-4">
                                {Object.keys(weeklyShoppingList[category]).map((ingredientName) => {
                                    const { unit, quantity, days } = weeklyShoppingList[category][ingredientName];
                                    return (
                                        <li key={ingredientName} className="flex justify-between mb-1 pb-1 border-b">
                                            <span className="text-sm md:text-base text-gray-800">{ingredientName}</span>
                                            <span className="font-semibold text-gray-600 text-right leading-4">
                                                {quantity} {unit} <br />
                                                <span className='font-thin text-xs'>{days.join(', ')}</span>
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            ) : (
                // Daily shopping list by category
                <div className="mb-6">
                    {
                        Object.keys(shoppingList[selectedDay] || {}).length === 0 && (
                            <p className="text-gray-700 text-sm">Remplissez votre planning de repas pour voir votre liste de course.</p>
                        )
                    }
                    {Object.keys(shoppingList[selectedDay] || {}).map((category) => (
                        <div key={category} className="mb-4">
                            <h5 className="uppercase font-semibold text-sm text-gray-700 mb-2">{FOOD_CATEGORY_FR[String(category)]}</h5>
                            <ul className="pl-2 md:pl-4">
                                {Object.keys(shoppingList[selectedDay][category]).map((ingredientName) => {
                                    const { unit, quantity } = shoppingList[selectedDay][category][ingredientName];
                                    return (
                                        <li key={ingredientName} className="flex justify-between mb-1 pb-1 border-b">
                                            <span className="text-sm md:text-base text-gray-800">{ingredientName}</span>
                                            <span className="font-semibold text-gray-600">
                                                {quantity} {unit}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ShoppingList;
