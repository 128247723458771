import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { calculateAge } from '../services/utils';
import { ArrowPathIcon } from '@heroicons/react/24/solid'
import { useNotification } from '../provider/NotificationProvider';
import apiClient from '../services/apiClient';
import { useUserProvider } from '../provider/UserProvider';

export interface ProfileInputFieldProps {
    label: string;
    name: string;
    type: string;
    value: string | number | null;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface ProfileSelectFieldProps {
    label: string;
    name: string;
    value: string | number | null;
    options: { label: string; value: string | number }[];
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export interface ProfileRadioFieldProps {
    label: string;
    name: string;
    options: { label: string; value: string }[];
    value: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ProfileInputField: React.FC<ProfileInputFieldProps> = ({ label, name, type, value, onChange }) => {
    return (
        <div className="mb-4">
            <label className="md:w-[400px] block text-gray-700 mb-2">{label}</label>
            <input
                type={type}
                name={name}
                value={value || ''}
                onChange={onChange}
                className="border p-2 w-full rounded-md"
            />
        </div>
    );
};

export const ProfileRadioField: React.FC<ProfileRadioFieldProps> = ({ label, name, options, value, onChange }) => {
    return (
        <div className="mb-4">
            <label className="block mb-2 text-gray-700">{label}</label>
            <div className="flex gap-4">
                {options.map((option) => (
                    <label key={option.value}>
                        <input
                            type="radio"
                            name={name}
                            value={option.value}
                            checked={value === option.value}
                            onChange={onChange}
                        />
                        {option.label}
                    </label>
                ))}
            </div>
        </div>
    );
};

export const ProfileSelectField: React.FC<ProfileSelectFieldProps> = ({ label, name, value, options, onChange }) => {
    return (
        <div className="mb-4">
            <label className="block mb-2 text-gray-700 md:w-[400px]">{label}</label>
            <select name={name} value={value || ''} onChange={onChange} className="border rounded-md p-2 w-full">
                <option value="" className='w-full'>Choisir</option>
                {options.map((option) => (
                    <option key={option.value} value={option.value} className='w-full'>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};



interface DietInfoCardProps {
    bmi: number;
    bmiReview: string;
    estimatedGoalTimeline: number;
    calorieDailyIntake: number;
    proteinIntake: number;
    carbIntake: number;
    fatIntake: number;
}
export const DietInfoCard: React.FC<DietInfoCardProps> = ({
    bmi,
    bmiReview,
    estimatedGoalTimeline,
    calorieDailyIntake,
    proteinIntake,
    carbIntake,
    fatIntake,
}) => {
    return (
        <div className="flex flex-col gap-9 border-gray-300">

            {/* BMI Section */}
            <div className="flex flex-col gap-2 text-gray-700">
                <p className="font-bold">
                    Votre indice de masse corporelle
                </p>
                <div className="flex gap-2">
                    <span className="w-fit bg-rose-100 text-rose-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {bmi}
                    </span>
                    <span className="w-fit bg-rose-100 text-rose-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {bmiReview}
                    </span>
                </div>
            </div>

            {/* Goal Timeline Section */}
            {
                estimatedGoalTimeline > 0 ?
                    <div className="flex flex-col gap-2 text-gray-700">
                        <p className="font-bold">
                            Vous atteindrez votre objectif dans
                        </p>
                        <div className="flex gap-2">
                            <span className="w-fit bg-rose-100 text-rose-500 font-bold px-3 py-1 rounded-lg text-sm">
                                {estimatedGoalTimeline} semaines
                            </span>
                        </div>
                    </div>
                    :
                    <div className="flex flex-col gap-2 text-gray-700">
                        <p className="font-bold">
                            Vous avez déjà atteint votre objectif, bravo!
                        </p>
                    </div>
            }

            {/* Caloric and Macronutrient Intake Section */}
            <div className="flex flex-col gap-2 text-gray-700">
                <p className="font-bold">
                    Cette semaine il vous faudra
                </p>
                <div className="flex gap-2">
                    <span className="w-fit bg-indigo-100 text-indigo-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {calorieDailyIntake} kcal / jour
                    </span>
                </div>
                {/* <p className="font-bold">
                    ...ce qui peut correspondre à
                </p>
                <div className="flex flex-wrap gap-2">
                    <span className="w-fit bg-red-100 text-red-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {proteinIntake}g de protéines / jour
                    </span>
                    <span className="w-fit bg-yellow-100 text-yellow-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {carbIntake}g de glucides / jour
                    </span>
                    <span className="w-fit bg-green-100 text-green-500 font-bold px-3 py-1 rounded-lg text-sm">
                        {fatIntake}g de lipides / jour
                    </span>
                    <span className="w-fit bg-gray-100 text-gray-500 font-bold px-3 py-1 rounded-lg text-sm">
                        + d'autres nutriments
                    </span>
                </div> */}
            </div>
        </div>
    );
};


const EditProfile: React.FC = () => {
    const { addNotification } = useNotification();
    const { profile, setProfile } = useUserProvider();

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;

        if (profile) {
            if (type === 'number') {
                setProfile({ ...profile, [name]: parseInt(value) });
            } else {
                setProfile({ ...profile, [name]: value });
            }
        }
    };

    const handleSubmit = async () => {
        const authTokenAccess = Cookies.get('authToken_access');

        if (!authTokenAccess || !profile) {
            addNotification('error', 'No authentication token or profile data found.');
            return;
        }

        apiClient.put('/api/user-info/', profile, {
            headers: {
                'Authorization': `Bearer ${authTokenAccess}`,
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            setProfile(profile);
            addNotification('success', 'Profile mis a jour avec succes!');
        }).catch((error) => {
            addNotification('error', error.message);
        })

    };

    if (!profile) {
        return <div>Loading profile...</div>;
    }

    return (
        <div className='md:w-1/2 md:p-4 md:px-8'>

            <div className="flex gap-2 mb-4">

                <span className='px-4 py-1 rounded-lg bg-gray-800 text-gray-50 w-fit'>
                    {calculateAge(profile.birthdate)} ans
                </span>
                <span className='px-4 py-1 rounded-lg bg-gray-800 text-gray-50 w-fit'>
                    {profile.height} cm
                </span>
                <span className='px-4 py-1 rounded-lg bg-gray-800 text-gray-50 w-fit'>
                    {
                        profile.gender === 'male' ? 'Homme' : 'Femme'
                    }
                </span>
            </div>
            {/* <ProfileInputField
                label="Votre date de naissance"
                name="birthdate"
                type="date"
                value={profile.birthdate || ''}
                onChange={handleInputChange}
            /> */}


            <ProfileInputField
                label="Votre poids (kg)"
                name="weight"
                type="number"
                value={profile.weight || ''}
                onChange={handleInputChange}
            />

            {/* <ProfileInputField
                label="Votre taille (cm)"
                name="height"
                type="number"
                value={profile.height || ''}
                onChange={handleInputChange}
            />

            <div className='flex items-center gap-4 mb-4'>
                <label className=" block font-medium text-gray-700">Vous êtes</label>
                <RadioButton
                    radioDisplay={['Un homme', 'Une femme']}
                    radioValues={['male', 'female']}
                    selectedValue={profile.gender || 'male'}
                    setSelectedValue={(value) => { setProfile({ ...profile, gender: value }) }}
                />
            </div> */}


            <ProfileInputField
                label="Votre poids à atteindre (kg)"
                name="weight_goal"
                type="number"
                value={profile.weight_goal || ''}
                onChange={handleInputChange}
            />

            {
                (profile.weight_goal && profile.weight && profile.weight_goal !== profile.weight) ?
                    <ProfileSelectField
                        label={'Nombre de kg à' + (profile.weight_goal > profile.weight ? ' gagner' : ' perdre') + ' par semaine'}
                        name="gain_per_week"
                        value={profile.gain_per_week || ''}
                        options={
                            profile.weight_goal > profile.weight ?
                                [
                                    { label: '0.25 kg / semaine', value: 0.25 },
                                    { label: '0.5 kg / semaine', value: 0.50 },
                                    { label: '0.75 kg / semaine', value: 0.75 },
                                    { label: '1 kg / semaine', value: 1.00 }
                                ] :
                                [
                                    { label: '-0.25 kg / semaine', value: -0.25 },
                                    { label: '-0.5 kg / semaine', value: -0.50 },
                                    { label: '-0.75 kg / semaine', value: -0.75 },
                                    { label: '-1 kg / semaine', value: -1.00 }
                                ]}
                        onChange={handleInputChange}
                    />
                    :
                    null
            }
            <ProfileSelectField
                label="Activité quotidienne"
                name="activity_level"
                value={profile.activity_level}
                options={[
                    { label: "Très peu actif (travail de bureau, peu ou pas de sport)", value: 'sedentary' },
                    { label: "Légèrement actif (marche quotidienne, peu de sport)", value: 'light' },
                    { label: "Modérément actif (activités régulières, 1-3 séances de sport/semaine)", value: 'moderate' },
                    { label: "Très actif (travail physique ou 4-5 séances de sport/semaine)", value: 'very_active' },
                    { label: "Extrêmement actif (sport intense ou travail très physique)", value: 'extremely_active' }

                ]}
                onChange={handleInputChange}
            />

            <ProfileSelectField
                label="Nombre de repas par jour"
                name="meal_schedule"
                value={profile.meal_schedule}
                options={[
                    // { label: "1 (dejeuner)", value: 1 },
                    // { label: "2 (dejeuner, diner)", value: 2 },
                    { label: "3 (petit dej, dejeuner, diner)", value: 3 },
                    { label: "4 (petit dej, dejeuner, goûter, diner)", value: 4 },
                    { label: "5 (petit dej, dejeuner, goûter, diner, colation)", value: 5 },
                    { label: "6 (petit dej, dejeuner, goûter, diner, colation, colation)", value: 6 }
                ]}
                onChange={handleInputChange}
            />

            <div
                onClick={handleSubmit}
                className="bg-blue-500 w-fit self-end cursor-pointer flex items-center gap-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                <ArrowPathIcon className="h-5 w-5" />
                Mettre à jour
            </div>
        </div>
    );
};

export default EditProfile;