import { Recipe } from "../types/recipeType";
import { _CARBOHYDRATE, _FAT, _PROTEIN, getEnergyFromNutrientList, getNutrientFromNutrientList } from "./foodService";
import apiClient from "./apiClient";

export const getUserRecipes = async ({ token }: { token: string }): Promise<Recipe[] | undefined> => {
    try {
        const response = await apiClient.get('/api/recipe/', {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data as Recipe[];
    } catch (error) {
        console.error('Error fetching user recipes:', error);
        return undefined;
    }
};


export const getUserRecipesDetailed = async ({ token }: { token: string }): Promise<Recipe[] | undefined> => {
    try {
        const response = await apiClient.get('/api/recipe/?detailed=true', {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
        let recipes: Recipe[] = [];
        response.data.forEach((recipe: any) => {
            recipes.push(serializeRecipeDetailsFromAPI(recipe));
        });

        return recipes;
    } catch (error) {
        console.log(error);
        return undefined;
    }
};

export const updateRecipeFavorite = async (recipe: Recipe, token: string) => {
    try {
        if (recipe.is_favorite) {
            apiClient.delete(`/api/recipe/favorites/${recipe.id}/`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(() => {
                return true
            })

        } else {
            apiClient.post(`/api/recipe/favorites/${recipe.id}/`, null, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            }).then(() => {
                return true
            })
        }
    } catch (error) {
        console.log(error);
        return false

    }
}

// Serialize recipe json from the the api to be used in the frontend
export const serializeRecipeDetailsFromAPI = (recipe: any): Recipe => {
    return {
        ...recipe,
        name: recipe.name,
        ingredients: recipe.ingredients.map((ingredient: any) => ({
            ...ingredient,
            fdc_id: ingredient.fdc_id,
            quantity: Number(ingredient.quantity),
            unit: ingredient.unit,
            nutriments_100g: {
                calories: getEnergyFromNutrientList(ingredient.food_details.nutrients),
                proteins: Number(getNutrientFromNutrientList(ingredient.food_details.nutrients, _PROTEIN)?.amount),
                fats: Number(getNutrientFromNutrientList(ingredient.food_details.nutrients, _FAT)?.amount),
                carbohydrates: Number(getNutrientFromNutrientList(ingredient.food_details.nutrients, _CARBOHYDRATE)?.amount),
            },
            nutriments: {
                calories: Math.round(getEnergyFromNutrientList(ingredient.food_details.nutrients) * ingredient.quantity / 100),
                proteins: Math.round(getNutrientFromNutrientList(ingredient.food_details.nutrients, _PROTEIN)?.amount * ingredient.quantity / 100),
                fats: Math.round(getNutrientFromNutrientList(ingredient.food_details.nutrients, _FAT)?.amount * ingredient.quantity / 100),
                carbohydrates: Math.round(getNutrientFromNutrientList(ingredient.food_details.nutrients, _CARBOHYDRATE)?.amount * ingredient.quantity / 100),
            },
            portion_name: ingredient.portion_name,
            portion_quantity: ingredient.portion_quantity,
            portion_gram_weight: ingredient.portion_gram_weight
        }))
    };
}

// Serialize recipe for API type
export const serializeRecipeAPI = (recipe: Recipe) => {
    return {
        name: recipe.name,
        type: recipe.type,
        ingredients: recipe.ingredients.map((ingredient) => ({
            fdc_id: ingredient.fdc_id,
            quantity: Number(ingredient.quantity),
            unit: ingredient.unit,
            portion_name: ingredient.portion_name,
            portion_quantity: ingredient.portion_quantity,
            portion_gram_weight: ingredient.portion_gram_weight
        }))
    };
}