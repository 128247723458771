import React, { useState, useEffect } from 'react';
import { Ingredient } from '../types/recipeType';
import { recalculateIngredientNutrimentsQuantity } from '../services/diet';
import Cookies from 'js-cookie';
import TooltipComponent from './Tooltip';
import { CheckIcon } from '@heroicons/react/24/solid'
import apiClient from '../services/apiClient';

// To parse an ingredient from the API Open Food Facts
// Use only for the first time (thats why its set to 100)
const ingredientParser = (ingredientAPI: any): Ingredient => {
    return {
        fdc_id: ingredientAPI.fdc_id,
        name: ingredientAPI.description,
        data_type: ingredientAPI.data_type,
        nutriments_100g: {
            calories: Math.round(ingredientAPI.nutrients.energy?.amount),
            proteins: Math.round(ingredientAPI.nutrients.protein?.amount),
            fats: Math.round(ingredientAPI.nutrients.fat?.amount),
            carbohydrates: Math.round(ingredientAPI.nutrients.carbohydrate?.amount)
        },
        nutriments: {
            calories: Math.round(ingredientAPI.nutrients.energy?.amount),
            proteins: Math.round(ingredientAPI.nutrients.protein?.amount),
            fats: Math.round(ingredientAPI.nutrients.fat?.amount),
            carbohydrates: Math.round(ingredientAPI.nutrients.carbohydrate?.amount)
        },
        quantity: ingredientAPI.portion.length > 0 ? (ingredientAPI.portion[0].amount * ingredientAPI.portion[0].gram_weight) : 100,
        unit: "grammes",
        portion: ingredientAPI.portion.length > 0 ? ingredientAPI.portion : null,
        portion_name: ingredientAPI.portion.length > 0 ? ingredientAPI.portion[0].modifier : "grammes",
        portion_quantity: ingredientAPI.portion.length > 0 ? ingredientAPI.portion[0].amount : 100,
        portion_gram_weight: ingredientAPI.portion.length > 0 ? ingredientAPI.portion[0].gram_weight : 1
    }
}

const IngredientCard: React.FC<{ _ingredient: Ingredient, handleIngredientClick: (ingredient: Ingredient) => void; }> = ({ _ingredient, handleIngredientClick }) => {
    // const [cardOpened, setCardOpened] = useState(true);
    const [ingredient, setIngredient] = useState(_ingredient);
    // const [loaded, setLoaded] = useState(false);

    const changeQuantity = (newQuantity: number) => {
        setIngredient({
            ...ingredient,
            quantity: ingredient.portion_gram_weight * newQuantity, // quantity in gram
            nutriments: recalculateIngredientNutrimentsQuantity(ingredient, ingredient.portion_gram_weight * newQuantity),
            portion_quantity: newQuantity
        }
        )
    };

    const updatePortion = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedOption = e.target.selectedOptions[0]; // Get the selected option element

        // Access custom data attributes
        // const portionId = selectedOption.value;
        const portionAmount = selectedOption.getAttribute('data-amount'); // number of portion
        const portionName = selectedOption.getAttribute('data-name');
        const portionWeight = selectedOption.getAttribute('data-weight'); // weight in gram of the selected portion

        setIngredient({
            ...ingredient,
            quantity: Number(portionWeight) * Number(portionAmount),
            nutriments: recalculateIngredientNutrimentsQuantity(ingredient, Number(portionWeight) * Number(portionAmount)),
            portion_name: portionName || "grammes",
            portion_quantity: Number(portionAmount),
            portion_gram_weight: Number(portionWeight)
        })
    };

    useEffect(() => {
        if (ingredient.portion) {
            setIngredient({
                ...ingredient,
                portion_name: ingredient.portion[0].modifier,
                portion_quantity: parseFloat(ingredient.portion[0].amount),
                portion_gram_weight: parseFloat(ingredient.portion[0].gram_weight),
                nutriments: recalculateIngredientNutrimentsQuantity(ingredient, parseFloat(ingredient.portion[0].gram_weight) * parseFloat(ingredient.portion[0].amount)),
                quantity: parseFloat(ingredient.portion[0].gram_weight) * parseFloat(ingredient.portion[0].amount)
            });
        }
        changeQuantity(ingredient.portion_quantity);
    }, []);

    return (
        <div
            className="bg-gray-50 rounded-lg py-2 px-2 w-full transition duration-200 hover:bg-gray-100"
        >
            <div className="flex justify-between items-center">
                {ingredient.name}
                {
                    ingredient.data_type === "foundation_food" && (
                        <TooltipComponent
                            tooltipText="Ingédient brut, recommandé"
                            className='w-fit text-xs text-white bg-green-400 p-1 rounded-full'
                        >
                            <CheckIcon className='w-4 h-4' />
                        </TooltipComponent>
                    )
                }
            </div>

            <div className='flex justify-between gap-2'>
                <span className='w-1/4 text-left'>
                    <span className='uppercase text-xs'>Calories</span>
                    <br /> {ingredient.nutriments.calories || "N/A"}kcal
                </span>
                <span className='w-1/4 text-left'>
                    <span className='uppercase text-xs'>Proteins</span>
                    <br /> {ingredient.nutriments.proteins || "N/A"}g
                </span>
                <span className='w-1/4 text-left'>
                    <span className='uppercase text-xs'>Fats</span>
                    <br /> {ingredient.nutriments.fats || "N/A"}g
                </span>
                <span className='w-1/4 text-left'>
                    <span className='uppercase text-xs'>Carbs</span>
                    <br /> {ingredient.nutriments.carbohydrates || "N/A"}g
                </span>
            </div>

            <div className='mt-2'>
                <div className="flex items-center gap-2 mb-2">
                    <input
                        type="number"
                        placeholder="Chercher un ingredient"
                        value={ingredient.portion_quantity}
                        onChange={(e) => changeQuantity(Number(e.target.value))}
                        className="bg-white rounded-lg border py-1 px-2 w-24"
                    />
                    <select name="type" id="" className="w-full rounded-lg py-1 px-1" onChange={(e) => updatePortion(e)}>
                        {
                            ingredient.portion &&
                            ingredient.portion?.map((portion: any) => (
                                <option key={portion.id} value={portion.id} datatype='number' data-amount={portion.amount} data-name={portion.modifier} data-weight={portion.gram_weight}>
                                    {portion.modifier} ({portion.gram_weight}g)
                                </option>
                            ))
                        }
                        <option value={'grammes'} datatype='number' data-amount={100} data-name={'grammes'} data-weight={1} >
                            grammes
                        </option>
                    </select>
                </div>
                <div className='bg-gray-900 cursor-pointer transition duration-200 hover:bg-gray-800 text-white rounded-lg py-1 text-center w-full' onClick={() => handleIngredientClick(ingredient)}>
                    Ajouter ingredient
                </div>
            </div>

        </div>
    );
};

// Define the component
const FoodSearch: React.FC<{ addIngredient: (ingredient: Ingredient) => void }> = ({ addIngredient }) => {

    const [query, setQuery] = useState<string>("");  // Store the query string
    const [results, setResults] = useState<Ingredient[]>([]);  // Store the search results
    const [loading, setLoading] = useState<boolean>(false);  // Store loading state
    const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);  // Store the timeout ID

    // Function to handle the search in Open Food Facts API
    const searchFood = async (searchTerm: string) => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/api/food/search/`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('authToken_access')}`,  // Adjust this if you're using another method for auth
                    'Content-Type': 'application/json'
                },
                params: {
                    search: searchTerm,
                    // search_simple: 1,
                    // action: 'process',
                    // json: 1,
                    // lc: 'fr',  // Only return results in French
                    page_size: 5  // Limit the results to 7 items
                }
            });
            const ingredients: Ingredient[] = response.data.map((ingr: any) => (ingredientParser(ingr)));
            console.log("ingredients", ingredients);

            setResults(ingredients);
        } catch (error) {
            console.error("Error fetching food data", error);
        }
        setLoading(false);
    };

    // useEffect to handle debounce
    useEffect(() => {
        // Clear previous timeout if it exists
        if (debounceTimeout) {
            clearTimeout(debounceTimeout);
        }

        // Set a new timeout for 200ms
        const newTimeout = setTimeout(() => {
            if (query.length > 2) {
                searchFood(query);
            } else {
                setResults([]);
            }
        }, 300);

        setDebounceTimeout(newTimeout);

        // Cleanup function to clear timeout when component unmounts or query changes
        return () => {
            if (newTimeout) {
                clearTimeout(newTimeout);
            }
        };
    }, [query]);

    // Handle the input change
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleIngredientSelected = (ingredient: Ingredient) => {
        setResults([]);
        setQuery("");
        addIngredient(ingredient);
    };

    return (
        <div className="w-full p-2 bg-white rounded-lg shadow">
            <input
                type="text"
                placeholder="Ajouter un ingredient"
                value={query}
                onChange={handleInputChange}
                className="bg-gray-50 rounded-lg border py-2 px-4 w-full mb-2"
            />
            {loading && <p>Chargement...</p>}
            {!loading && results.length > 0 && (
                <div className="flex flex-col gap-2 overflow-y-scroll md:max-h-[600px]">
                    {results.map((ingredient, index) => (
                        <IngredientCard
                            key={index}
                            _ingredient={ingredient}
                            handleIngredientClick={handleIngredientSelected} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default FoodSearch;