import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { UserProfile } from '../pages/Profile';
import { getUserDietInfo, UserDietInfo } from '../services/diet';
import apiClient from '../services/apiClient';
import { useNotification } from './NotificationProvider';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { CredentialResponse } from '@react-oauth/google';

interface UserContextType {
    profile: UserProfile;
    setProfile: (profile: UserProfile) => void;
    dietInfo: UserDietInfo;
    setDietInfo: (dietInfo: UserDietInfo) => void;
    authToken_access: string;
    setAuthToken_access: (authToken_access: string) => void;
    handleGoogleSuccess: (response: CredentialResponse) => void;
    handleGoogleFailure: () => void;
    userProviderReady: boolean;
    handleLogout: () => void;
}

// Create the context
const UserContext = createContext<UserContextType>({
    profile: {} as UserProfile,
    setProfile: () => { },
    dietInfo: {} as UserDietInfo,
    setDietInfo: () => { },
    authToken_access: '',
    setAuthToken_access: () => { },
    handleGoogleSuccess: () => { },
    handleGoogleFailure: () => { },
    userProviderReady: false,
    handleLogout: () => { },
});

export const useUserProvider = () => useContext(UserContext);


// Context provider component
export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [profile, setProfile] = useState<UserProfile>({} as UserProfile); // Initially undefined until data loads
    const [dietInfo, setDietInfo] = useState<UserDietInfo>({} as UserDietInfo); // Initially undefined until data loads
    const [userProviderReady, setUserProviderReady] = useState(false);
    const [authToken_access, setAuthToken_access] = useState(Cookies.get('authToken_access') || '');
    const [authToken_refresh, setAuthToken_refresh] = useState(Cookies.get('authToken_refresh') || '');
    const { addNotification } = useNotification();
    const navigate = useNavigate();

    const addAuthToken = (access: string, refresh: string) => {
        Cookies.set('authToken_access', access, { expires: 1 });
        Cookies.set('authToken_refresh', refresh, { expires: 1 });
        setAuthToken_access(access);
        setAuthToken_refresh(refresh);
    };

    const removeAuthToken = () => {
        Cookies.remove('authToken_access');
        Cookies.remove('authToken_refresh');
        setAuthToken_access('');
        setAuthToken_refresh('');
    };


    // -----------------------------------------------
    // GOOGLE
    // -----------------------------------------------

    // Define the success handler for Google Login
    const handleGoogleSuccess = async (response: CredentialResponse) => {
        if (response.credential) {
            apiClient.post(`/api/google-login/`, {
                token: response.credential,
                birthdate: profile.birthdate,
                gender: profile.gender,
                height: profile.height,
                weight: profile.weight,
                weight_goal: profile.weight_goal,
                activity_level: profile.activity_level,
                gain_per_week: profile.gain_per_week,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            }).then((res) => {
                if (res.data.token) {
                    // Set cookies for the access and refresh tokens
                    addAuthToken(res.data.token.access, res.data.token.refresh);
                } else {
                    addNotification('error', 'Failed to retrieve token from backend.');
                }
            }).catch((error) => {
                if (error.response.data.error === 'NOT_SIGNED_UP') {
                    addNotification('warning', 'Vous n\'avez pas encore de compte. Veuillez vous inscrire pour continuer.');
                } else {
                    addNotification('error', 'Erreur durant la connexion : ' + error.response.data.error, 7000);
                }
                removeAuthToken();
            })
        } else {
            addNotification('error', 'Il y a un problème avec la connexion Google, no credential');
        }
    };

    // Handle failure
    const handleGoogleFailure = () => {
        addNotification('error', 'Il y a un problème avec la connexion Google');
        removeAuthToken();
    };

    // -----------------------------------------------
    // LOGOUT
    // -----------------------------------------------

    // Use useCallback to memoize the handleLogout function
    const handleLogout = async () => {
        apiClient.post('/api/logout/', {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('authToken_access')}`,
            }
        }).then((response) => {
            // Remove the auth token from cookies
            addNotification('success', 'Deconnexion reussie.');
            navigate('/');
        }).catch((error) => {
            addNotification('error', 'Erreur lors de la deconnexion : ' + error);
        }).finally(() => {
            setProfile({} as UserProfile);
            removeAuthToken();
        });
    };

    useEffect(() => {
        // Check connexion, load user profile
        setUserProviderReady(false)

        if (!authToken_access) {
            // addNotification('warning', 'Votre session a expiré, veuillez vous reconnecter.');
            // navigate('/');
            setUserProviderReady(true);
            return;
        }

        apiClient.get('/api/user-info/', {
            headers: {
                Authorization: `Bearer ${authToken_access}`,
            },
        }).then((response) => {
            const data: UserProfile = response.data;
            setProfile(data);
            setDietInfo(getUserDietInfo(data));
            navigate('/dashboard');
        }).catch((error) => {
            addNotification('warning', 'Votre session a expiré, veuillez vous reconnecter.');
            console.log(error);
            handleLogout();
            navigate('/');
        }).finally(() => {
            setUserProviderReady(true);
        })
    }, [authToken_access]);

    useEffect(() => {
        if (userProviderReady) {
            setDietInfo(getUserDietInfo(profile));
        }
    }, [profile]);

    return (
        <UserContext.Provider value={{
            profile,
            setProfile,
            dietInfo,
            setDietInfo,
            authToken_access,
            setAuthToken_access,
            handleGoogleSuccess,
            handleGoogleFailure,
            handleLogout,
            userProviderReady
        }}>
            {children}
        </UserContext.Provider>
    );
};