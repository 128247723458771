// Notification.tsx
import React, { useEffect } from 'react';

interface NotificationProps {
  type: 'success' | 'info' | 'error' | 'warning' | 'default';
  message: string;
  duration?: number;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ type, message, duration = 5000, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, onClose]);

  const getTypeStyles = () => {
    switch (type) {
      case 'success':
        return 'bg-green-500';
      case 'info':
        return 'bg-blue-500';
      case 'error':
        return 'bg-red-500';
      case 'warning':
        return 'bg-yellow-500';
      default:
        return 'bg-gray-500';
    }
  };

  return (
    <div
      className={`min-w-[300px] z-50 p-4 rounded shadow-lg text-white flex items-center justify-between ${getTypeStyles()}`}
    >
      <p>{message}</p>
      <button className="ml-4 text-white" onClick={onClose}>
        &times;
      </button>
    </div>
  );
};

export default Notification;
