import { Nutriments } from "./foodType";
import { BREAKFAST } from "./mealType";

export interface Ingredient {
    fdc_id: number;
    name: string;
    barcode?: string;
    data_type?: string;
    nutriments_100g: Nutriments;
    nutriments: Nutriments;
    quantity: number; // quantity in g
    unit: string; // grams
    portion: any[] | null;
    portion_name: string; // name of the selected portion (if none then grams)
    portion_quantity: number; // quantity of the selected portion (if none then grams)
    portion_gram_weight: number; // gram equivalent of 1 selected portion (if none then grams)
}

export const RECIPE_TYPE_BREAKFAST = 'BREAKFAST';
export const RECIPE_TYPE_MEAL = 'MEAL';
export const RECIPE_TYPE_SNACK = 'SNACK';
export const RECIPE_TYPE_DESSERT = 'DESSERT';
export const RECIPE_TYPE_OTHER = 'OTHER';

export type RecipeType = 
    | typeof RECIPE_TYPE_BREAKFAST
    | typeof RECIPE_TYPE_MEAL
    | typeof RECIPE_TYPE_SNACK
    | typeof RECIPE_TYPE_DESSERT
    | typeof RECIPE_TYPE_OTHER;

export interface Recipe {
    id?: number;
    name: string;
    type: RecipeType;
    is_public: boolean;
    ingredients: Ingredient[];
    is_favorite: boolean;
}

export const RECIPE_TYPE_FR = {
    BREAKFAST: 'Petit déjeuner',
    MEAL: 'Repas',
    SNACK: 'Collation',
    DESSERT: 'Dessert',
    OTHER: 'Autre',
}