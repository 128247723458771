import React, { useEffect, useState } from 'react';
import { recalculateNutritionalValues, UserDietInfo } from '../services/diet';
import { getUserRecipes, getUserRecipesDetailed, serializeRecipeDetailsFromAPI } from '../services/recipeService';
import Cookies from 'js-cookie';
import { Ingredient, Recipe } from '../types/recipeType';
import { calculateMacrosFromIngredients, emptyMeal, optimizeMeal, recipeToMeal, serializeMealAPI } from '../services/mealService';
import { TrashIcon, CheckIcon, PlusIcon, ArrowPathIcon, AdjustmentsHorizontalIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import { formatDate, roundToClosestMultipleOf5 } from '../services/utils';
import TooltipComponent from './Tooltip';
import { Nutriments } from '../types/foodType';
import { Meal, MEAL_TYPE_FR, MealType } from '../types/mealType';
import { keepInterestedNutrientsOnly } from '../services/foodService';
import { useNotification } from '../provider/NotificationProvider';
import apiClient from '../services/apiClient';

const CARD_STATE_EMPTY: string = "CARD_STATE_EMPTY";
const CARD_STATE_ADD_RECIPE: string = "CARD_STATE_ADD_RECIPE";
const CARD_STATE_WITH_MEAL: string = "CARD_STATE_WITH_MEAL";

export const DayHeader: React.FC<{ day: Date, index: number, dietInfo: UserDietInfo, mealPlanning: { [day: string]: { [key in MealType]?: Meal } } }> = ({ day, index, dietInfo, mealPlanning }) => {

    const [macrosConsoumed, setMacrosConsumed] = useState<Nutriments>({
        calories: 0,
        proteins: 0,
        fats: 0,
        carbohydrates: 0
    })

    const calculateMacroForDay = (macro_name: string) => {
        let _res: number = 0;
        ['BREAKFAST', 'SNACK_1', 'LUNCH', 'SNACK_2', 'DINNER'].forEach((mealType: string) => {
            _res += mealPlanning[day.toISOString().split('T')[0]][mealType as MealType]?.calculated_macros[macro_name as keyof Nutriments] || 0;
        })
        return Math.round(_res);
    }

    useEffect(() => {
        if (dietInfo) {
            setMacrosConsumed({
                calories: calculateMacroForDay('calories'),
                proteins: calculateMacroForDay('proteins'),
                fats: calculateMacroForDay('fats'),
                carbohydrates: calculateMacroForDay('carbohydrates')
            })
        }
    }, [mealPlanning])

    return (
        <div className="flex flex-col sticky top-0 bg-white p-2 border-b border-gray-300">
            <span className="capitalize mb-2 text-gray-800 font-semibold">{formatDate(day)}</span>
            <div className="flex items-center gap-2 mb-2">
                <span className="bg-indigo-100 w-fit px-2 py-0 rounded-md text-indigo-600 font-normal text-sm font-bold">
                    <span className="font-bold">{Math.round(macrosConsoumed.calories / dietInfo.dailyCalories['day' + (index + 1)].calories * 100)}</span>%
                </span>
                <span className="bg-indigo-100 w-fit px-2 py-0 rounded-md text-indigo-600 font-normal text-sm">
                    <span className="font-bold">{roundToClosestMultipleOf5(macrosConsoumed.calories)}</span> / {dietInfo.dailyCalories['day' + (index + 1)].calories} <span className='uppercase text-[8px] leading-3'>kCalories conseillées</span>
                </span>
            </div>
            {/* <div className="flex items-center gap-2 mb-2">
                <span className="bg-red-100 w-fit px-2 py-0 rounded-md text-red-600 font-normal text-sm">
                    <span className="font-bold"></span> {dietInfo.dailyCalories['day' + (index + 1)].proteins}g <span className='uppercase text-[8px] leading-3'>de protéines conseillées</span>
                </span>
            </div>
            <div className="flex items-center gap-2 mb-2">
                <span className="bg-green-100 w-fit px-2 py-0 rounded-md text-green-600 font-normal text-sm">
                    <span className="font-bold"></span> {dietInfo.dailyCalories['day' + (index + 1)].carbohydrates}g <span className='uppercase text-[8px] leading-3'>de lipides conseillés</span>
                </span>
            </div>
            <div className="flex items-center gap-2">
                <span className="bg-yellow-100 w-fit px-2 py-0 rounded-md text-yellow-600 font-normal text-sm">
                    <span className="font-bold"></span> {dietInfo.dailyCalories['day' + (index + 1)].proteins}g <span className='uppercase text-[8px] leading-3'>de glucides conseillées</span>
                </span>
            </div> */}

        </div>
    )
}

export const MealItem: React.FC<{ meal: Meal, setMeal: (meal: Meal) => void }> = ({ meal, setMeal }) => {
    const [cardState, setCardState] = useState(CARD_STATE_EMPTY);
    const [recipes, setRecipes] = useState<Recipe[] | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const [inrgredientsVisible, setIngredientsVisible] = useState(false);


    const token = Cookies.get('authToken_access') as string;
    const { addNotification } = useNotification();

    // Function to load recipes when the user clicks "Ajouter une recette"
    const loadRecipes = async () => {
        setLoading(true);
        let fetchedRecipes: Recipe[] = [];
        if (meal.mealGenerationRunning) {
            fetchedRecipes = await getUserRecipesDetailed({ token }) as Recipe[];
        } else {
            fetchedRecipes = await getUserRecipes({ token }) as Recipe[];
        }
        setRecipes(fetchedRecipes);
        setLoading(false);
    };

    const addRecipeToMeal = (recipe: Recipe) => {
        setCardState(CARD_STATE_EMPTY);
        setLoading(true);
        if (meal.mealGenerationRunning) {
            const _meal = recipeToMeal(recipe, meal);
            setMeal({
                ...meal,
                ..._meal,
                is_optimized: false
            });
            setCardState(CARD_STATE_EMPTY);
            setLoading(false);
        } else {
            apiClient.get(`/api/recipe/${recipe.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            }).then((recipeJSON) => {
                const _recipe = serializeRecipeDetailsFromAPI(recipeJSON.data);
                const _meal = recipeToMeal(_recipe, meal);
                // console.log(_meal, _recipe);
                apiClient.post('/api/meal/', serializeMealAPI(_meal), {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }).then((response) => {
                    const _meal_ready = {
                        ..._meal,
                        id: response.data.id,
                        ingredients: response.data.ingredients,
                        calculated_macros: response.data.calculated_macros,
                        is_optimized: false,
                    }
                    setMeal(_meal_ready);
                    // updateCachedMeal(_meal_ready);
                    addNotification('success', 'Recette ajoutée avec succès !');
                }).catch((error) => {
                    addNotification('error', 'Une erreur est survenue lors de l\'ajout de la recette: ' + error)
                });

            }).catch((error) => {
                console.error(error);
                addNotification('error', 'Une erreur est survenue lors de l\'ajout de la recette: ' + error)
            }).finally(() => {
                setLoading(false);
            })
        }
    };

    const deleteMeal = () => {

        if (confirmDelete) {
            if (meal.mealGenerationRunning) {
                setMeal(emptyMeal(meal));
                setCardState(CARD_STATE_EMPTY);
                setConfirmDelete(false);
                console.log("deleteMeal", meal);
            } else {

                apiClient.delete(`/api/meal/${meal.id}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }).then((response) => {
                    setCardState(CARD_STATE_EMPTY);
                    setConfirmDelete(false);
                    setMeal(emptyMeal(meal));
                    addNotification('success', 'Recette supprimée avec succès !');
                }).catch((error) => {
                    addNotification(
                        'error',
                        'Une erreur est survenue lors de la suppression de la recette' + error
                    )
                });
            }
        } else {
            setConfirmDelete(true);
        }
    };

    const handleOptimizeMeal = () => {
        if (!meal.is_optimized) {

            if (meal.mealGenerationRunning) {
                setMeal(optimizeMeal(meal));
                addNotification('success', 'Repas optimisé avec succès !');
            } else {
                apiClient.get(`/api/meal/${meal.id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                }).then((response) => {

                    meal.ingredients = response.data.ingredients.map((ingredient: any): Ingredient => {
                        const nutriments_100g = keepInterestedNutrientsOnly(ingredient.food_details);
                        return {
                            ...ingredient,
                            nutriments_100g: nutriments_100g,
                            nutriments: recalculateNutritionalValues(nutriments_100g, 100, ingredient.quantity),
                        };
                    })
                    meal.calculated_macros = calculateMacrosFromIngredients(meal.ingredients);

                    const _optimizedMeal = optimizeMeal(meal);

                    apiClient.put(`/api/meal/${meal.id}/`, serializeMealAPI({ ..._optimizedMeal, is_optimized: true }), {
                        headers: {
                            'Authorization': `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    }).then((response) => {
                        setMeal(_optimizedMeal);
                        addNotification('success', 'Repas optimisé avec succès !');
                    }).catch((error) => {
                        addNotification('error', 'Une erreur est survenue lors de l\'optimisation du repas : ' + error);
                        console.error(error);
                    })
                }).catch((error) => {
                    addNotification('error', 'Une erreur est survenue lors de l\'optimisation du repas : ' + error);
                })
            }

        }
    }


    useEffect(() => {
        if (cardState === CARD_STATE_ADD_RECIPE && !recipes)
            loadRecipes()
    }, [cardState]);

    useEffect(() => {
        if (meal.ingredients.length > 0) {
            setCardState(CARD_STATE_WITH_MEAL);
        }
    }, [meal]);

    return (
        <div className=''>
            {
                cardState === CARD_STATE_EMPTY && (
                    <div className='shadow flex flex-col justify-center rounded bg-white p-2'>
                        <span className='text-xs text-gray-600 uppercase mb-1'>{meal && (MEAL_TYPE_FR[meal.type]).toLowerCase()}</span>
                        <span className="bg-indigo-100 w-fit px-2 py-0 rounded-md text-indigo-600 font-normal text-sm">
                            <span className="font-bold">{meal && meal.calculated_macros.calories}</span> / {meal && meal.macrosRequired.calories} <span className='uppercase text-[8px] leading-3'>kCal</span>
                        </span>
                        {
                            loading ? (
                                <div className='mt-2'>
                                    <p className='text-gray-600'>Chargement...</p>
                                </div>
                            ) : (
                                <div className='flex items-center bg-gray-100 rounded-lg py-2 mt-2 cursor-pointer transition duration-200 hover:bg-gray-50' onClick={() => setCardState(CARD_STATE_ADD_RECIPE)}>
                                    <PlusIcon className='w-4 h-4 text-gray-600 mx-4' />
                                    <span className='text-sm text-gray-800' >
                                        Ajouter une recette
                                        {/* Ajouter <span className='text--500'>{meal && (MEAL_TYPE_FR[meal.type]).toLowerCase()}</span> */}
                                    </span>
                                </div>
                            )
                        }
                        {/* <span>Générer une recette par IA</span> */}
                    </div>
                )
            }




            {
                cardState === CARD_STATE_ADD_RECIPE && (
                    <>
                        <div className='fixed flex justify-center items-center top-0 left-0 w-full h-full z-10 bg-black/90 cursor-default' onClick={() => { if (meal.ingredients.length > 0) setCardState(CARD_STATE_WITH_MEAL); else setCardState(CARD_STATE_EMPTY) }}>
                            <div className=' shadow bg-white w-[600px] max-h-[95vh] h-fit border-dashed md:rounded-lg p-4 flex flex-col justify-center items-center opacity-100' onClick={(e) => e.stopPropagation()}>
                                {loading ? (
                                    <div>Chargement des recettes...</div> // Show loading state while recipes are being fetched
                                ) : (
                                    <div className='w-full'>
                                        <div className="flex justify-between mb-5">
                                            <p>Choisissez une de vos recettes</p>
                                            <span onClick={() => { if (meal.ingredients.length > 0) setCardState(CARD_STATE_WITH_MEAL); else setCardState(CARD_STATE_EMPTY) }}>
                                                <PlusIcon className='w-6 h-6 rotate-45 transition duration-200 hover:cursor-pointer hover:rotate-110 hover:text-rose-500' />
                                            </span>
                                        </div>

                                        <div className='flex flex-col flex-wrap overflow-y-scroll'>


                                            {
                                                (meal.type === 'SNACK_1' || meal.type === 'SNACK_2' || meal.type === 'SNACK_3' || meal.type === 'BREAKFAST') && recipes && (
                                                    <div>
                                                        <span className='text-sm text-gray-600'>Les petits déjeuners</span>
                                                        {
                                                            recipes
                                                                .filter((recipe) => recipe.type === 'BREAKFAST')
                                                                .map((recipe, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className='flex flex-col w-fit mb-2 border-l-2 px-1 cursor-pointer transition duration-200 hover:bg-gray-50 hover:border-rose-500'
                                                                        onClick={() => addRecipeToMeal(recipe)}
                                                                    >
                                                                        <span className='text-sm text-gray-700'>
                                                                            {recipe.name}
                                                                        </span>
                                                                    </div>
                                                                ))
                                                        }
                                                    </div>
                                                )
                                            }
                                            {
                                                (meal.type === 'LUNCH' || meal.type === 'DINNER') && recipes && (
                                                    <div>
                                                        <span className='text-sm text-gray-600 mt-4'>Les repas</span>
                                                        {
                                                            recipes.filter((recipe) => recipe.type === 'MEAL').map((recipe, index) => (
                                                                <div key={index} className='flex flex-col w-fit mb-2 border-l-2 px-1 cursor-pointer transition duration-200 hover:bg-gray-50 hover:border-rose-500' onClick={() => addRecipeToMeal(recipe)}>
                                                                    <span className='text-sm text-gray-700'>
                                                                        {recipe.name}
                                                                    </span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }

                                            {
                                                (meal.type === 'SNACK_1' || meal.type === 'SNACK_2' || meal.type === 'SNACK_3' || meal.type === 'BREAKFAST') && recipes && (
                                                    <div>
                                                        <span className='text-sm text-gray-600 mt-4'>Les collations</span>
                                                        {

                                                            recipes.filter((recipe) => recipe.type === 'SNACK').map((recipe, index) => (
                                                                <div key={index} className='flex flex-col w-fit mb-2 border-l-2 px-1 cursor-pointer transition duration-200 hover:bg-gray-50 hover:border-rose-500' onClick={() => addRecipeToMeal(recipe)}>
                                                                    <span className='text-sm text-gray-700'>
                                                                        {recipe.name}
                                                                    </span>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                )
                                            }


                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='w-full h-32 border rounded shadow'>
                            {/* Just for fake the card in the abckgournf (just for desgn) */}
                        </div>
                    </>
                )
            }





            {
                cardState === CARD_STATE_WITH_MEAL && (
                    // <div className=' flex flex-col justify-center border-l border-gray-300  p-2 relative '>
                    <div className='bg-white rounded flex flex-col justify-center shadow p-2 relative '>
                        <span className='text-xs text-gray-600 uppercase mb-1'>{meal && (MEAL_TYPE_FR[meal.type]).toLowerCase()}</span>
                        <span className="bg-indigo-100 w-fit px-2 py-0 rounded-md text-indigo-600 font-normal text-sm">
                            <span className="font-bold">{meal && (meal.calculated_macros.calories)}</span> / {meal && roundToClosestMultipleOf5(meal.macrosRequired.calories)} <span className='uppercase text-[8px] leading-3'>kCal</span>
                        </span>

                        <div className="flex justify-between items-center py-2">
                            <span className='text-gray-700 font-semibold'>
                                {meal.recipe_name}
                            </span>
                            <TooltipComponent tooltipText={inrgredientsVisible ? "Cacher les ingrédients" : "Afficher les ingrédients"} className=''>

                                {
                                    inrgredientsVisible ? (
                                        <EyeIcon className='w-4 h-4 text-green-300 ml-auto cursor-pointer' onClick={() => setIngredientsVisible(false)} />
                                    ) : (
                                        <EyeSlashIcon className='w-4 h-4 text-red-300 ml-auto cursor-pointer' onClick={() => setIngredientsVisible(true)} />
                                    )
                                }

                            </TooltipComponent>
                        </div>

                        <div className=''>
                            <div className='flex justify-between'>
                                {
                                    meal.is_optimized &&
                                    <div className='flex items-center gap-1 text-xs rounded rounded-full w-fit hidden'>
                                        <div className='p-1 bg-green-300 rounded-full'>
                                            <CheckIcon className='w-3 h-3 text-white' />
                                        </div>
                                        <p className='text-green-500'>Repas optimisé</p>
                                    </div>
                                }
                            </div>

                            <div className={'mt-2 ' + (inrgredientsVisible ? 'block' : 'hidden')}>
                                {meal.ingredients.map((ingredient, index) => (
                                    <div key={index} className=' text-gray-600 text-sm overflow-hidden'>
                                        <span>{ingredient.name}</span>
                                        <p className='text-gray-900 text-right'>
                                            {Math.round(ingredient.portion_name ? ingredient.portion_quantity : ingredient.quantity)} {ingredient.portion_name ? ingredient.portion_name : ingredient.unit}
                                            <span className='text-gray-500 text-xs'> {ingredient.nutriments?.calories}kcal</span>
                                        </p>
                                    </div>
                                ))}
                            </div>

                            <div className='relative mt-2 flex justify-between items-center gap-1'>
                                <TooltipComponent tooltipText={"Supprimer ce repas"} className='bg-red-50 flex justify-center w-full p-1 px-4 rounded-lg cursor-pointer transition duration-200 hover:bg-red-100' onClick={deleteMeal}>
                                    {
                                        confirmDelete ? (
                                            <p className='text-red-500 text-xs'>Supprimer?</p>
                                        ) : (
                                            <TrashIcon className='w-4 h-4 text-red-500' />
                                        )
                                    }
                                </TooltipComponent>
                                <TooltipComponent tooltipText={"Remplacer la recette de ce repas"} className='bg-gray-50 flex justify-center w-full p-1 px-4 rounded-lg cursor-pointer transition duration-200 hover:bg-gray-100' onClick={() => setCardState(CARD_STATE_ADD_RECIPE)}>
                                    <ArrowPathIcon className='w-4 h-4 text-gray-500' />
                                </TooltipComponent>
                                {/* TODO: Add a recipe or ingredient 
                                 <TooltipComponent tooltipText={"Ajouter une recette ou un ingrédient"} className='bg-green-50 flex justify-center w-full p-1 px-4 rounded-lg cursor-pointer transition duration-200 hover:bg-green-100'>
                                    <PlusIcon className='w-4 h-4 text-green-500' />
                                </TooltipComponent> */}
                                <TooltipComponent
                                    tooltipText={meal.is_optimized ? "Cet repas est déjà optimisé !" : "Optimiser ce repas"}
                                    className={'flex justify-center p-1 px-4 rounded-lg transition duration-200 hover:bg-teal-100 ' + (meal.is_optimized ? 'bg-transparent hover:bg-transparent w-fit text-green-400' : 'w-full cursor-pointer text-orange-500 bg-orange-50 hover:bg-orange-100')}
                                    onClick={handleOptimizeMeal}>
                                    <AdjustmentsHorizontalIcon className='w-4 h-4' />
                                </TooltipComponent>
                            </div>
                        </div>
                    </div>
                )
            }


        </div>
    );
};