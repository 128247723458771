import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { useUserProvider } from '../provider/UserProvider';

const CustomGoogleLogin: React.FC<{ text: string }> = ({ text = 'signin_with' }) => {
  const { handleGoogleSuccess, handleGoogleFailure } = useUserProvider();
  // Using GoogleLogin component directly
  return (
    <GoogleOAuthProvider clientId="69074667909-pd746hmt697q0i51bt58hggj1hq27fge.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={handleGoogleSuccess}
        onError={handleGoogleFailure}
        size='large'
        shape='rectangular'
        locale='fr-FR'
        text={text as 'continue_with' | 'signin_with' | 'signup_with'} 
        logo_alignment='center'
        theme='filled_black'
        // useOneTap
      />
    </GoogleOAuthProvider>
  );
};

export default CustomGoogleLogin;
