import { useEffect, useState } from "react";
import { Recipe, RECIPE_TYPE_FR } from "../types/recipeType";
import Cookies from "js-cookie";
import { StarIcon } from "@heroicons/react/24/solid";
import TooltipComponent from "./Tooltip";
import { updateRecipeFavorite } from "../services/recipeService";


export const RecipePublicCard: React.FC<{ recipe: Recipe, setRecipe: any }> = ({ recipe, setRecipe }) => {
  const [openIngredients, setOpenIngredients] = useState<boolean>(false);

  const handleFavoriteRecipe = () => {
    updateRecipeFavorite(recipe, Cookies.get('authToken_access')!).then(() => {
      setRecipe({
        ...recipe,
        is_favorite: !recipe.is_favorite
      })

    }).catch((error) => {
      console.log(error);
    })
  }

  return (
    <>
      <div className={"relative flex flex-col w-full bg-white rounded-lg shadow-md h-fit " + (openIngredients ? "rounded-b-none" : "")} onMouseEnter={() => setOpenIngredients(true)} onMouseLeave={() => setOpenIngredients(false)}>
        <div className='relative'>
          <img src={process.env.PUBLIC_URL + '/food_bg.jpg'} alt="" className="w-full rounded-t-lg h-16 md:h-28 object-cover" />
          <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] rounded-t-lg"></div>
        </div>
        {/* <div className='absolute top-1 right-1 flex gap-2 rounded-md p-1'>
          <span className='inline-block uppercase text-xs text-rose-700 bg-rose-50 px-2 py-1 rounded-md'>{RECIPE_TYPE_FR[recipe.type]}</span>
        </div> */}
        <div className="p-2">
          <div className="flex justify-between items-center">
            <h3 className="font-semibold text-gray-800">{recipe.name}</h3>
            <div className="cursor-pointer" onClick={handleFavoriteRecipe}>
              <TooltipComponent tooltipText={recipe.is_favorite ? "Retirer des favoris" : "Ajouter aux favoris"}>
                <StarIcon className={"h-6 w-6 " + (recipe.is_favorite ? "text-yellow-400" : "text-gray-300")} />
              </TooltipComponent>
            </div>
          </div>
          <div className={'p-2 shadow-md rounded-b-lg transform bg-white duration-200 ease-in-out absolute w-full ' + (openIngredients ? 'z-30 block' : 'hidden')} style={{ top: '100%', left: 0 }}>

            <div className="text-gray-600">
              {recipe.ingredients.map((ingredient, index) => (
                <div key={index} className="flex flex-col border-t py-1">
                  <span>{ingredient.portion_quantity || Math.round(ingredient.quantity)} {ingredient.portion_name || 'g'}</span>
                  <span className="font-semibold">{ingredient.name}</span>
                </div>
              ))}
            </div>
          </div>

        </div>
      </div>
    </>
  );
};
