import axios from 'axios';

// Create an Axios instance with the base URL set from environment variables
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Base URL for all requests
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // if you need to send cookies or authentication tokens
});

export default apiClient;
